<!--快检表单-->
<template>
    <div class="container">
        <div class="quickCheckForm" v-loading="loading">
            <div class="form-content">
                <div class="invoiceInfo">
                    <table>
                        <tbody>
                            <tr>
                                <th align="right">
                                    登记人：
                                </th>
                                <td>{{ dataInfo.quality.creator_name }}</td>
                                <th align="right">
                                    登记时间：
                                </th>
                                <td>{{ dataInfo.quality.created }}</td>
                                <th align="right">
                                    快检编号：
                                </th>
                                <td>{{ dataInfo.quality.no }}</td>
                                <th align="right">
                                    过磅单编号：
                                </th>
                                <td>{{ dataInfo.balance.balance_number }}</td>
                            </tr>
                            <tr>
                                <th colspan="8" class="tr_border_th">
                                    <div class="tr_border"></div>
                                </th>
                            </tr>
                            <tr>
                                <th align="right">
                                    供应商：
                                </th>
                                <td>{{ dataInfo.balance.supplier_name }}</td>
                                <th align="right">
                                    原材料分类：
                                </th>
                                <td>{{ dataInfo.balance.type_name }}</td>
                                <th align="right">
                                    原材料子类：
                                </th>
                                <td>{{ dataInfo.balance.child_type_name }}</td>
                                <th align="right">
                                    原材料规格：
                                </th>
                                <td>{{ dataInfo.balance.spec_name }}</td>
                            </tr>
                            <tr>
                                <th colspan="8" class="tr_border_th">
                                    <div class="tr_border"></div>
                                </th>
                            </tr>
                            <tr>
                                <th align="right">
                                    货源地：
                                </th>
                                <td>{{ dataInfo.balance.source_name }}</td>
                                <th align="right">
                                    毛重：
                                </th>
                                <td>{{ dataInfo.balance.gross_weight }}</td>
                                <th align="right">
                                    皮重：
                                </th>
                                <td>{{ dataInfo.balance.tare_weight }}</td>
                                <th align="right">
                                    净重：
                                </th>
                                <td>{{ dataInfo.balance.net_weight }}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div class="check-wrapper check-items " v-if="versionMark != 1">
                    <!-- <div class="title">
                        快检项目
                    </div> -->
                    <div class="head_title_container">
                        <div>
                            <div class="side1"></div>
                            <div class="side2"></div>
                            <span class="title">快检项目</span>
                            <div class="side2"></div>
                            <div class="side1"></div>
                        </div>
                    </div>
                    <div class="table-bg">
                        <el-table
                            :data="dataInfo.qualityDetails"
                            style="width: 100%"
                            class="table-list"
                            height="2.5rem"
                        >
                            <el-table-column
                                prop="item_name"
                                label="快检项目"
                            >
                                <template slot-scope="scope">
                                    <i style="color: red" v-if="scope.row.is_must_check">* </i>{{ scope.row.item_name }}
                                </template>
                            </el-table-column>
                            <el-table-column
                                prop="standard_index"
                                label="合格指标"
                                class-name="notice-cell"
                            >
                                <template slot-scope="scope">
                                    {{ getUnitStr(scope.row.standard_index,scope.row.unit,scope.row.index_type) }}
                                </template>
                            </el-table-column>
                            <el-table-column
                                prop="compromise_index"
                                label="让步指标"
                            >
                                <template slot-scope="scope">
                                    {{ getUnitStr(scope.row.compromise_index,scope.row.unit,scope.row.index_type) }}
                                </template>
                            </el-table-column>
                            <el-table-column
                                prop="not_standard_index"
                                label="不合格指标"
                            >
                                <template slot-scope="scope">
                                    {{ getUnitStr(scope.row.not_standard_index,scope.row.unit,scope.row.index_type) }}
                                </template>
                            </el-table-column>
                            <el-table-column
                                prop="actual_value"
                                label="实际指标"
                            >
                                <template slot-scope="scope">
                                    <el-input
                                        v-if="scope.row.index_type==0"
                                        v-model="scope.row.actual_value"
                                        placeholder="请输入"
                                        @input="setResult(scope.row)"
                                        :disabled="operatetype==2||(isEnabledStandard&&isAdjust)"
                                    >
                                        <template slot="suffix">
                                            {{ scope.row.unit }}
                                        </template>
                                    </el-input>
                                    <el-select
                                        v-if="scope.row.index_type==1"
                                        v-model="scope.row.actual_value"
                                        @change="setResult(scope.row)"
                                        :disabled="operatetype==2||(isEnabledStandard&&isAdjust)"
                                    >
                                        <el-option
                                            v-if="scope.row.standard_index"
                                            :label="scope.row.standard_index+scope.row.unit"
                                            :value="scope.row.standard_index"
                                        ></el-option>
                                        <el-option
                                            v-if="scope.row.compromise_index"
                                            :label="scope.row.compromise_index+scope.row.unit"
                                            :value="scope.row.compromise_index"
                                        ></el-option>
                                        <el-option
                                            v-if="scope.row.not_standard_index"
                                            :label="scope.row.not_standard_index+scope.row.unit"
                                            :value="scope.row.not_standard_index"
                                        ></el-option>
                                    </el-select>
                                </template>
                            </el-table-column>
                            <el-table-column
                                prop="unit"
                                label="扣吨百分比"
                            >
                                <template slot-scope="scope">
                                    {{ scope.row.deduction_weight_rate===null?'——':scope.row.deduction_weight_rate+'%' }}
                                </template>
                            </el-table-column>
                            <el-table-column
                                prop="result"
                                label="检测结果"
                            >
                                <template slot-scope="scope">
                                    <span
                                        :style="{...getResultText(scope.row.result).style, width: '.7rem'}"
                                    >{{ getResultText(scope.row.result).txt }}</span>
                                </template>
                            </el-table-column>
                        </el-table>
                    </div>
                </div>
                <div class="check-wrapper test-result">
                    <!-- <div class="title">
                        质检结果
                    </div> -->
                    <div class="head_title_container">
                        <div>
                            <div class="side1"></div>
                            <div class="side2"></div>
                            <span class="title">质检结果</span>
                            <div class="side2"></div>
                            <div class="side1"></div>
                        </div>
                    </div>
                    <div class="check-result">
                        <el-radio-group
                            v-model="dataInfo.quality.result"
                            :disabled="operatetype==2||isEnabledStandard"
                            @change="changeResult"
                            size="small"
                        >
                            <el-radio :label="1" border>
                                合格
                            </el-radio>
                            <el-radio :label="2" border>
                                让步
                            </el-radio>
                            <el-radio :label="3" border>
                                不合格
                            </el-radio>
                        </el-radio-group>
                    </div>
                </div>
                <div class="check-wrapper check-items">
                    <!-- <div class="title">
                        处理结果
                    </div> -->
                    <div class="head_title_container">
                        <div>
                            <div class="side1"></div>
                            <div class="side2"></div>
                            <span class="title">处理结果</span>
                            <div class="side2"></div>
                            <div class="side1"></div>
                        </div>
                    </div>
                    <table>
                        <tr>
                            <th>处理结果：</th>
                            <td>
                                <el-radio-group
                                    v-model="dataInfo.quality.handle_result"
                                    :disabled="operatetype==2"
                                >
                                    <el-radio :label="1" disabled>
                                        合格接收
                                    </el-radio>
                                    <el-radio :label="2" disabled>
                                        让步接收
                                    </el-radio>
                                    <el-radio :label="3" :disabled="[null,0,1,2].includes(dataInfo.quality.result)">
                                        退货
                                    </el-radio>
                                    <el-radio :label="4" :disabled="[null,0,1,2].includes(dataInfo.quality.result)">
                                        不合格接收
                                    </el-radio>
                                </el-radio-group>
                            </td>
                            <th>扣吨：</th>
                            <td>
                                <el-input
                                    placeholder=""
                                    v-model="dataInfo.quality.deduction_weight"
                                    :disabled="operatetype==2||isEnabledStandard"
                                ></el-input>
                            </td>
                            <th>扣吨百分比：</th>
                            <td>
                                <el-input
                                    placeholder=""
                                    v-model="dataInfo.quality.deduction_weight_rate"
                                    disabled
                                >
                                    <template slot="suffix">
                                        {{ dataInfo.quality.deduction_weight_rate==null?'——':'%' }}
                                    </template>
                                </el-input>
                            </td>
                        </tr>
                        <tr>
                            <th>料仓号：</th>
                            <td colspan="1">
                                <el-input
                                    placeholder="选择料仓"
                                    v-model="dataInfo.balance.stock_no"
                                    readonly
                                    @focus="showStockPop"
                                    style="cursor: pointer"
                                    :disabled="operatetype==2"
                                ></el-input>
                            </td>
                            <th>料仓名称：</th>
                            <td colspan="1">
                                {{ dataInfo.balance.stock_name }}
                            </td>
                            <th>原发数量：</th>
                            <td colspan="1">
                                <el-input placeholder="" v-model="dataInfo.quality.delivery_weight" readonly></el-input>
                            </td>
                        </tr>
                        <tr>
                            <th>基础含水率(%)：</th>
                            <td>
                                <el-input
                                    class="readonly_input"
                                    placeholder=""
                                    v-model="dataInfo.quality.base_water_content"
                                    readonly
                                ></el-input>
                            </td>
                            <th>实际含水率(%)：</th>
                            <td colspan="3">
                                <el-input
                                    style="width: 38%"
                                    placeholder=""
                                    v-model="dataInfo.quality.water_content"
                                    :disabled="operatetype==2||isEnabledStandard"
                                >
                                </el-input>
                            </td>
                        </tr>
                        <tr>
                            <th>处理意见：</th>
                            <td colspan="5">
                                <el-input placeholder="请输入" v-model="dataInfo.quality.remarks" :disabled="operatetype==2"></el-input>
                            </td>
                        </tr>
                    </table>
                </div>
                <div class="check-wrapper">
                    <!-- <div class="title">
                        添加照片
                    </div> -->
                    <div class="head_title_container">
                        <div>
                            <div class="side1"></div>
                            <div class="side2"></div>
                            <span class="title">添加照片</span>
                            <div class="side2"></div>
                            <div class="side1"></div>
                        </div>
                    </div>
                    <div class="img-list">
                        <el-upload
                            action=""
                            accept=".jpeg,.png,.jpg,.bmp,.gif"
                            list-type="picture-card"
                            :on-preview="handlePictureCardPreview"
                            :on-remove="handleRemove"
                            :auto-upload="false"
                            :file-list="fileList"
                            :on-change="onChangeFile"
                            :limit="10"
                            multiple
                            :disabled="operatetype==2"
                        >
                            <i class="el-icon-plus"></i>
                        </el-upload>
                    </div>
                </div>
            </div>
            <div class="btns" v-if="operatetype==1&&!isBusinessForm">
                <el-button
                    class="close"
                    @click="handleSave(1)"
                    :disabled="isAdjust"
                >
                    保存
                </el-button>

                <el-button
                    v-if="dataInfo.quality.handle_result==4&&!business_instance_id"
                    class="save"
                    @click="handleSave(2)"
                >
                    提交审批
                </el-button>

                <el-button
                    v-else
                    class="save"
                    @click="handleSave(2)"
                    :disabled="isEnabledStandard&&!dataInfo.quality.handle_result"
                >
                    确定
                </el-button>
            </div>
        </div>
    </div>
</template>

<script>
import NP from 'number-precision';
NP.enableBoundaryChecking(false);
export default {
    name: 'quick-check-form',
    props: {
        extr: Object,
        vueFormParams: Object,
    },
    data() {
        return {
            buCode: 'ProductionAffix',
            aliyunOssToken: null,
            loading: false,
            biid: '',
            // 流程id
            business_instance_id: '',
            // 操作类型： 1编辑 2查看
            operatetype: 1,
            dataInfo: {
                // 基本信息
                balance: {},
                // 处理结果
                quality: {},
                // 快检信息
                qualityDetails: [],
            },
            // 文件列表
            fileList: [],

            // 文件分组id
            filesGroupId: '',

            btnLoading: false,

            // 是否启用标准
            isEnabledStandard: false,
            // 是否是调整
            isAdjust: false,
            // 必需验证的是否验证完成
            isMustAllCheck: false,

            isBusinessForm: false,
            // 版本标识[1普通版本，2高级版]
            versionMark: 1,

        };
    },
    watch: {},
    created() {
        this.versionMark = window.$globalHub.$store.state.versionMark;

        this.getOssToken();
        if (this.extr && this.extr.biid) {
            this.biid = this.extr.biid;
            this.operatetype = this.extr.IsView ? 2 : 1;
            this.business_instance_id = this.extr.business_instance_id;
        } else {
            this.isBusinessForm = true;
            // 流程
            this.biid = '';
            this.business_instance_id = this.vueFormParams.businessInstanceId;
            this.operatetype = 2;
        }
        this.initPage();
    },
    methods: {
        // 初始化
        async initPage() {
            this.loading = true;
            await this.getData();
            await this.getStationStandardEnabled();
            await this.getFileList();
            this.loading = false;
        },
        // 获取数据
        async getData() {
            try {
                const url = `/interfaceApi/procurement/balance/quality/${this.operatetype}`
                    + `?biid=${this.biid}&business_instance_id=${this.business_instance_id}`;
                const res = await this.$axios.get(url);
                this.dataInfo.balance = res.balance || {};
                this.dataInfo.quality = res.quality || {};
                this.dataInfo.qualityDetails = res.qualityDetails.map(item => {
                    item.unit = item.unit || '';
                    return item;
                });
                if (this.dataInfo.quality.status === 1) {
                    this.isAdjust = true;
                }
                this.business_instance_id = res.quality.business_instance_id;
            } catch (e) {
                if (e.ErrorCode) {
                    this.$message.error(e.ErrorCode.Message);
                }
            }

        },
        // 保存
        async handleSave(type) {
            if (type === 2) {
                if (!this.dataInfo.quality.handle_result) {
                    this.$message.warning('请先完成质检');
                    return;
                }
            }

            if (this.btnLoading) {
                return;
            }
            this.btnLoading = true;
            try {
                const url = `/interfaceApi/procurement/balance/quality/save/${type}`;
                const res = await this.$axios.post(url, this.dataInfo);
                if (res) {
                    this.dataInfo = res;
                    await this.uploadFileMethod();
                    this.$message.success('操作成功');
                    if (type === 2) { // 确定或提交审批
                        this.$parent.hide();
                        // 如果是提交审批
                        if (this.dataInfo.quality.handle_result === 4 && !this.business_instance_id) {
                            const business_instance_id = res.quality.business_instance_id;
                            const BusinessProcessId = res.quality.BusinessProcessId;
                            this.openProcess(business_instance_id, BusinessProcessId);
                        }
                    }
                    window.updateTable();
                    this.btnLoading = false;
                }
            } catch (e) {
                if (e.ErrorCode) {
                    this.$message.error(e.ErrorCode.Message);
                }
            }
        },
        // 打开流程办理
        openProcess(business_instance_id, BusinessProcessId) {
            this.$WFShowPhaseLayer(business_instance_id, BusinessProcessId);
        },


        // 通过场站组织机构ID获取原材料是否启动规范快检
        async getStationStandardEnabled() {
            const url = `/interfaceApi/baseinfo/stationmanger/get_station_standard_quality_enabled/${this.dataInfo.balance.station_id}`;
            const res = await this.$axios.get(url,);
            if (res) {
                this.isEnabledStandard = true;
            }
        },
        // 上传文件
        async uploadFileMethod() {
            if (this.fileList.length === 0) {
                return;
            }
            // 如果没有文件分组 先创建文件分组
            if (!this.filesGroupId) {
                this.upGroupFun();
            }
            this.$nextTick(() => {
                for (const item of this.fileList) {
                    if (!item.fileId) {
                        // const formData = new FormData();
                        // formData.append('files', item.raw);
                        // formData.append('code', 'ProductionAffix');
                        // formData.append('data_id', this.dataInfo.quality.id);
                        // formData.append('group_id', this.filesGroupId);
                        // const fileId = await this.$axios.post(this.FILE.FILE_BASE_URL() + 'file/uploadfile', formData);
                        // item.fileId = fileId;
                        this.upGroupFun(item);
                    }
                }
            });
        },
        // 获取上传通行证
        getOssToken() {
            this.$axios.get(this.FILE.FILE_BASE_URL_OSS() + `credential/GetPostPolicy/${this.buCode}`).then(res => {
                if (res) {
                    this.aliyunOssToken = res;
                    // localStorage.setItem('aliyunOssToken', JSON.stringify(res));
                }
            });
        },
        // 上传组名
        upGroupFun(val) {
            const filename = val ? val.name : '现场照片';
            // 判断是否过期，过期重新调用获取通信证方法
            const formData = new FormData();
            // 注意formData里append添加的键的大小写
            formData.append('key', `${this.aliyunOssToken.dir}${this.dataInfo.quality.id}/${filename}`); // 存储在oss的文件路径
            formData.append('OSSAccessKeyId', this.aliyunOssToken.accessid); // accessKeyId
            formData.append('policy', this.aliyunOssToken.policy); // policy
            formData.append('Signature', this.aliyunOssToken.signature); // 签名
            // 如果是base64文件，那么直接把base64字符串转成blob对象进行上传就可以了
            formData.append('file', val ? val.raw : filename);
            // formData.append('FileDescription', this.newFile.FileDescription);
            formData.append('success_action_status', 200); // 成功后返回的操作码
            formData.append('Content-Type', 'multipart/form-data'); // 成功后返回的操作码

            // 回调参数
            const obj = {
                bucket: this.aliyunOssToken.bucket ? this.aliyunOssToken.bucket : this.buCode, // 存储桶名称
                business_type_code: this.buCode, // 业务类型编码
                object_name: `${this.aliyunOssToken.dir}${this.dataInfo.quality.id}/${filename}`, // 文件对象名
                show_name: filename, // 显示文件名
                size: 0, // 文件大小
                mimeType: '',
                mime_type: val ? val.raw : '',
                data_id: this.dataInfo.quality.id, // 业务数据id
                directory_id: '', // 目录id
                tenant_id: this.$takeTokenParameters('TenantId'), // 租户id
                // FileDescription: this.newFile.FileDescription, // 文件描述
            };
            this.uploadFun(formData, obj);
        },
        // 上传文件
        uploadFun(formData, val) {
            $.ajax({
                url: this.aliyunOssToken.host,
                type: 'POST',
                data: formData,
                cache: false, // 不缓存数据
                processData: false, // 不处理数据
                contentType: false, // 不设置内容类型
                success: data => { // 成功回调
                    this.callBackFun(val);
                },
            });
        },
        // 上传的回调
        callBackFun(obj) {
            this.$axios.post(this.FILE.FILE_BASE_URL_OSS() + 'api/UploadCallback', obj).then(res => {
                if (res) {
                    console.log('上传成功');
                }
            });
        },
        // 获取文件列表
        async getFileList() {
            if (this.dataInfo.quality.id) {
                // 1.先获取所有文件夹和文件
                try {
                    const url = this.FILE.FILE_BASE_URL_OSS() + `api/GetFileList/${this.dataInfo.quality.id}`;
                    const fileDataArr = await this.$axios.get(url);
                    fileDataArr.forEach(item => {
                        if (item.show_name === '现场照片') {
                            this.filesGroupId = item.id;
                        } else {
                            this.fileList.push({
                                fileId: item.id,
                                url: '',
                            });
                        }
                    });
                    this.$nextTick(() => {
                        this.getFileDetailFun(this.fileList);
                    });
                    // eslint-disable-next-line no-empty
                } catch (e) {}
            }
        },
        // 获取文件下载预览地址
        getFileDetailFun(data) {
            const arr = data;
            arr.forEach(item => {
                this.$axios
                    .get(this.FILE.FILE_BASE_URL_OSS() + 'api/GetFileInfo/' + item.fileId)
                    .then(res => {
                        item.url = res.preview_url;
                    })
                    .catch(error => {
                        this.$message.error(error.ErrorCode.Message);
                    });
            });
            this.fileData = arr;
        },
        // 文件上传到本地缓存
        onChangeFile(file, fileList) {
            this.fileList = fileList;
        },
        // 文件删除
        handleRemove(file) {
            let delIndex = null;
            const _that = this;
            for (const index in this.fileList) {
                if (file === this.fileList[index]) {
                    delIndex = index;
                    break;
                }
            }
            if (delIndex !== null) {
                this.fileList.splice(delIndex, 1);
                if (file.fileId) {
                    this.$axios
                        .delete(this.FILE.FILE_BASE_URL_OSS() + 'api/DeleteFileInfo/' + file.fileId, {
                        })
                        .then(() => {
                            _that.$message({
                                message: '删除成功！',
                                type: 'success',
                            });
                        })
                        .catch(error => {
                            _that.$message.error(error.ErrorCode.Message);
                        });
                }
            }
        },
        // 预览
        handlePictureCardPreview(file) {
            let url = '';
            if (file.fileId) {
                url = file.url;
            } else {
                url = URL.createObjectURL(file.raw);
            }
            this.previewImg([url]);
        },
        // 选择料仓弹框
        showStockPop() {
            this.$toast({
                title: true,
                text: '请选择料仓',
                type: 'eject',
                t_url: 'technicalQualityManage/material/quickCheckSetting/chooseStock',
                viewPosition: 'view',
                extr: {
                    biid: this.biid,
                    callBack: this.callBackChooseStock,
                },
            });
        },
        // 选择料仓
        callBackChooseStock(data) {
            this.dataInfo.balance.stock_id = data.stock_id;
            this.dataInfo.balance.stock_code = data.stock_code;
            this.dataInfo.balance.stock_name = data.stock_name;
            this.dataInfo.balance.stock_no = data.stock_no;
            this.dataInfo.balance.stock_material_id = data.stock_material_id;
            this.dataInfo.balance.team_id = data.team_id;
            this.dataInfo.balance.team_name = data.team_name;
        },
        // 获取指标字符串
        getItemStr(str) {
            str = str || '';
            return str.replace(/\|/g, '');
        },
        // 获取带单位的字符串
        getUnitStr(str, unit, index_type) {
            str = str || '';
            if (unit) {
                // 区间
                if (index_type === 0) {
                    const arr = str.split('|');
                    if (arr[1]) {
                        arr[1] += unit;
                    }
                    if (arr[4]) {
                        arr[4] += unit;
                    }
                    str = arr.join('|');
                } else {
                    // 指定值
                    // eslint-disable-next-line no-lonely-if
                    if (str) {
                        str += unit;
                    }
                }

            }
            return this.getItemStr(str);
        },
        // 设置结果
        setResult(item) {
            // 计算快检结果
            if (item.actual_value === '' || item.actual_value === null) {
                item.result = 0;
            } else if (item.standard_index && this.getExpression(item.standard_index, item.index_type, item.actual_value)) {
                item.result = 1;
                // eslint-disable-next-line no-eval
            } else if (item.compromise_index && this.getExpression(item.compromise_index, item.index_type, item.actual_value)) {
                item.result = 2;
            } else {
                item.result = 3;
            }
            // 设置扣吨
            this.setDeductionWeight(item);
            // 设置含水率
            this.setWater(item);
            // 初始化 合格个数，不合格个数，让步个数
            let standardNum = 0; let noStandardNum = 0; let compromiseNum = 0;
            // 所有的必检项 是否检测完
            let mustCheckAll = true;

            // 筛选所有:有一个不合格 测试结果为不合格/让步/合格
            for (const o of this.dataInfo.qualityDetails) {
                if (o.result === 1) {
                    standardNum++;
                } else if (o.result === 2) {
                    compromiseNum++;
                } else if (o.result === 3) {
                    noStandardNum++;
                }
                if (o.is_must_check && o.result === 0) {
                    mustCheckAll = false;
                }
            }
            // 最终计算总结果 按低级不合格开始...
            let defaultResult = 0;
            // 必检项全部检测完成 计算最终结果
            if (mustCheckAll) {
                if (noStandardNum > 0) {
                    defaultResult = 3;
                } else if (compromiseNum > 0) {
                    defaultResult = 2;
                } else if (standardNum > 0) {
                    defaultResult = 1;
                }
            }
            this.dataInfo.quality.handle_result = this.dataInfo.quality.result = defaultResult;
        },

        // 设置扣吨
        async setDeductionWeight(item) {
            if (!this.isEnabledStandard) {
                item.deduction_weight_rate = 0;
                return;
            }
            // 让步的时候 计算当前扣吨百分比
            if (item.result === 2 && item.actual_value !== '' && item.actual_value !== null) {
                if (item.index_type === 0 ) {
                    // 是否自动扣吨 且扣吨配置值存在
                    if (item.is_deduction_weight && item.out_value && item.deduction_weight_value) {
                        const arr = item.compromise_index.split('|');
                        const deduction_weight_value = item.deduction_weight_value.split('|')[0];
                        if (item.out_symbol === '超') {
                            const min = Math.min(arr[1], arr[4]);
                            // eslint-disable-next-line max-len
                            item.deduction_weight_rate = NP.times(NP.divide( NP.minus(item.actual_value, min), item.out_value), deduction_weight_value);
                        } else if (item.out_symbol === '低') {
                            const max = Math.max(arr[1], arr[4]);
                            // eslint-disable-next-line max-len
                            item.deduction_weight_rate = NP.times(NP.divide( NP.minus(max, item.actual_value), item.out_value), deduction_weight_value);
                        }
                    }
                    if (item.deduction_weight_rate < 0) {
                        item.deduction_weight_rate = 0;
                    }
                }
            } else {
                item.deduction_weight_rate = null;
            }

            // 计算累计扣吨
            let total = 0;
            for (const obj of this.dataInfo.qualityDetails) {
                if (obj.deduction_weight_rate) {
                    total += obj.deduction_weight_rate;
                }
            }
            this.dataInfo.quality.deduction_weight_rate = total;
        },
        // 设置含水率
        setWater(item) {
            if (item.item_name.indexOf('含水率') !== -1) {
                this.dataInfo.quality.water_content = item.actual_value;
            }
        },
        // 生成表达式js index_value指标对象
        /** *
         * 生成表达式js 判断是否符合当前指标
         * @param index_value  指标对象
         * @param index_type   指标类型
         * @param actual_value 当前设置的值
         * @returns {boolean}
         */
        getExpression(index_value, index_type, actual_value) {
            index_value = this.getItemStr(index_value);
            index_value = index_value.replaceAll('=', '==').replaceAll('≥', '>=').replaceAll('≤', '<=');
            let resultJs = true;
            // 指标类型区间
            if (index_type === 0) {
                const val = this.getItemStr(index_value);
                resultJs += '&&' + actual_value + val.replace('并且', '&&' + actual_value).replace('或者', '||' + actual_value);
                // 指标类型 指定值
            } else if (index_type === 1) {
                resultJs += `&&'${index_value}'=='${actual_value}'`;
            }
            // eslint-disable-next-line no-eval
            return eval(resultJs);
        },
        // 检测每项结果文本
        getResultText(result = 0) {
            const obj = {
                0: {
                    txt: '未检测',
                    color: '#979EA7',
                    style: {
                        color: '#979EA7',
                        background: '#fafafa',
                        border: '1px solid #979EA7',
                        padding: '.05rem .1rem',
                    },
                },
                1: {
                    txt: '合格',
                    color: '#00A00E',
                    style: {
                        color: '#00A00E',
                        background: '#F3FDF6',
                        border: '1px solid #00A00E',
                        padding: '.05rem .1rem',
                    },
                },
                2: {
                    txt: '让步',
                    color: '#FC9545',
                    style: {
                        color: '#FC9545',
                        background: '#FFF7F2',
                        border: '1px solid #FC9545',
                        padding: '.05rem .1rem',
                    },
                },
                3: {
                    txt: '不合格',
                    color: '#FF1500',
                    style: {
                        color: '#FF1500',
                        background: '#FFF6F4',
                        border: '1px solid #FF1500',
                        padding: '.05rem .1rem',
                    },
                },
            };
            return obj[result];
        },
        // 预览图片
        previewImg(urls) {
            this.$toast({
                title: true,
                text: '图片预览',
                type: 'eject',
                width: '15.4rem',
                t_url: 'picturePreview/index',
                extr: {
                    code: {
                        imgSrc: urls,
                    },
                    isImg: true,
                },
                viewPosition: 'components',
            });
        },

        changeResult(result) {
            this.dataInfo.quality.handle_result = result;
        },

    },

};
</script>

<style lang="stylus" scoped>
>>> .table-bg .el-table th
    background #F8F8F8
>>> .el-upload--picture-card
    border 1px dashed #006EFF !important
    i
        color #006EFF !important
.container
    width 100%;
    height 100%;
.quickCheckForm
    width 100%;
    height 100%;
    position relative
    background #fff
    .form-content
        padding 0.18rem;
        width 100%;
        height 100%
        padding-bottom 1rem;
        overflow auto
        .invoiceInfo
            border 1px solid #D7D7D7
            table
                width 100%
                border-collapse collapse
                th,td
                    border 2px solid #ffffff
                    font-size 0.14rem
                    line-height 0.4rem
                    font-weight 400
                    background #F8F8F8
                    border-bottom none !important
                th
                    width 1.1rem
                td
                    width 2.4rem
                    padding-left 0.2rem
                    .link-text
                        color: blue;
                        text-decoration: underline;
                        cursor: pointer;
        .base-info
            padding 0.1rem 0.3rem;
            box-shadow: 0rem 0.02rem 0.04rem 0rem rgba(0, 0, 0, 0.1);
            border: 1px solid #D7D7D7;
            .info-wrapper
                width 100%;
                padding 0.08rem 0
                display flex
                .info-item
                    width 25%;
                    font-size 0.16rem
                    color #666666
                    white-space nowrap
                    span
                        color #333
        .check-wrapper
            margin-top 0.18rem
            width 100%;
            // .title
            //     position relative
            //     width 0.9rem
            //     height 0.27rem
            //     display flex
            //     align-items center
            //     padding-left 0.12rem
            //     background: #E6F7FF;
            //     border: 1px solid #91D5FF;
            //     font-size 0.16rem
            //     color #2898FF
            //     &:after
            //         position absolute
            //         display flex
            //         content ''
            //         width 0.3rem;
            //         height 0.3rem
            //         background #fff
            //         border-bottom 1px solid #91D5FF;
            //         transform: rotate( 65deg);
            //         top: -0.09rem;
            //         right: -0.23rem;
            table
                margin-top -1px
                width 100%;
                box-sizing border-box
                border-collapse collapse
                th,td
                    font-size 0.16rem
                    height 0.38rem
                    line-height 0.38rem;
                    border: 1px solid #D7D7D7;
                th
                    width 1.6rem
                    color #022782
                    background: #F8F8F8;
                    padding-left 0.26rem
                    font-weight normal
                    text-align right
                td
                    color #333;
                    background #fff;
                    padding 0.05rem
                    .readonly_input
                        >>> .el-input__inner
                            background #F8F8F8  !important
                    >>> .el-radio-group
                        white-space nowrap
                        .el-radio
                            margin-right 0.15rem
                    >>> .el-input__inner
                        padding-left .05rem
                    >>> .is-disabled .el-input__inner
                        color #333;
                        background-color #F8F8F8
            >>> .cell
                padding 0.03rem 0
                font-size 0.16rem
                font-weight normal
            &.test-result
                .check-result
                    >>> .el-radio-group
                        .el-radio__label
                            color #666
                        .el-radio__input.is-checked .el-radio__inner
                            border-color: #00A00E;
                            background: #00A00E;
                        .el-radio__input.is-checked+.el-radio__label
                            color #00A00E
            .img-list
                width 100%;
                padding 0.1rem 0
                border-top 1px solid #D7D7D7
                >>> .el-upload-list__item,>>> .el-upload
                    width 0.8rem
                    height 0.8rem
                    line-height 0.8rem;
                    text-align center
                >>> .el-upload-list__item-status-label
                    display none
    >>> .btns
        width 100%;
        position absolute
        left 0
        bottom 0
        padding 0.15rem;
        display flex
        justify-content center
        background #fff;
        .el-button
            width: 2.6rem;
            height: 0.5rem;
            font-size 0.2rem
            margin 0 0.2rem
            &.is-disabled
                opacity 0.6
        .save
            color: #fff;
            background: #1577D2;
        .close
            color: #1577d2;
            background: #fff!important;
            border: 1px solid #1577d2;
    .table-bg
        >>> .el-radio__label
        display inline-block
        >>> .el-input__suffix
            display flex
            align-items center
            color #666
</style>
